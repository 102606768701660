import { default as ai_45form_45builderN457b6Vfd5Meta } from "/usr/src/app/client/pages/ai-form-builder.vue?macro=true";
import { default as editAIBDblgH77Meta } from "/usr/src/app/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexSH5TaRTW0nMeta } from "/usr/src/app/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexmPYy6sFEluMeta } from "/usr/src/app/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexADtKFLecWXMeta } from "/usr/src/app/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharewUd8P3124yMeta } from "/usr/src/app/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats2crGp1qfoaMeta } from "/usr/src/app/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsuS8CTTJdRqMeta } from "/usr/src/app/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showK41TTb8GcEMeta } from "/usr/src/app/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestOxe6FiTSDhMeta } from "/usr/src/app/client/pages/forms/create/guest.vue?macro=true";
import { default as indexLHNOATHabxMeta } from "/usr/src/app/client/pages/forms/create/index.vue?macro=true";
import { default as homevGTO74ez5BMeta } from "/usr/src/app/client/pages/home.vue?macro=true";
import { default as indexpvpOBIeCIIMeta } from "/usr/src/app/client/pages/index.vue?macro=true";
import { default as loginWJwg8vmwt5Meta } from "/usr/src/app/client/pages/login.vue?macro=true";
import { default as emailfT1ph2OPI3Meta } from "/usr/src/app/client/pages/password/email.vue?macro=true";
import { default as _91token_93ZobYEFLvcrMeta } from "/usr/src/app/client/pages/password/reset/[token].vue?macro=true";
import { default as pricing1rlXi5t3XSMeta } from "/usr/src/app/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyNZ36VaUnk1Meta } from "/usr/src/app/client/pages/privacy-policy.vue?macro=true";
import { default as registerwXaD3ciyvdMeta } from "/usr/src/app/client/pages/register.vue?macro=true";
import { default as access_45tokensNUQcgwF2QAMeta } from "/usr/src/app/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountLMqiuVKUNMMeta } from "/usr/src/app/client/pages/settings/account.vue?macro=true";
import { default as admin0pwYE3ykjNMeta } from "/usr/src/app/client/pages/settings/admin.vue?macro=true";
import { default as billingJrbHyxKDRmMeta } from "/usr/src/app/client/pages/settings/billing.vue?macro=true";
import { default as connectionsSW3KIwa3xhMeta } from "/usr/src/app/client/pages/settings/connections.vue?macro=true";
import { default as indexPFhNCEa17oMeta } from "/usr/src/app/client/pages/settings/index.vue?macro=true";
import { default as passwordmaAPVwfv7dMeta } from "/usr/src/app/client/pages/settings/password.vue?macro=true";
import { default as profileC4pJeHGgdWMeta } from "/usr/src/app/client/pages/settings/profile.vue?macro=true";
import { default as workspacePB5CDfFlLzMeta } from "/usr/src/app/client/pages/settings/workspace.vue?macro=true";
import { default as settingsNmDj0jVjhvMeta } from "/usr/src/app/client/pages/settings.vue?macro=true";
import { default as errorU3S0fxOq93Meta } from "/usr/src/app/client/pages/subscriptions/error.vue?macro=true";
import { default as successYQnNmryqT0Meta } from "/usr/src/app/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93G6770dyCnVMeta } from "/usr/src/app/client/pages/templates/[slug].vue?macro=true";
import { default as indexI6zxLZoInCMeta } from "/usr/src/app/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93toIlw7UjwUMeta } from "/usr/src/app/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesRN7Lr1vmIEMeta } from "/usr/src/app/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93K2L4W4pSHeMeta } from "/usr/src/app/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsFuCLDLYc9MMeta } from "/usr/src/app/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsbTPNyk6uBKMeta } from "/usr/src/app/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: ai_45form_45builderN457b6Vfd5Meta?.name ?? "ai-form-builder",
    path: ai_45form_45builderN457b6Vfd5Meta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builderN457b6Vfd5Meta || {},
    alias: ai_45form_45builderN457b6Vfd5Meta?.alias || [],
    redirect: ai_45form_45builderN457b6Vfd5Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editAIBDblgH77Meta?.name ?? "forms-slug-edit",
    path: editAIBDblgH77Meta?.path ?? "/forms/:slug()/edit",
    meta: editAIBDblgH77Meta || {},
    alias: editAIBDblgH77Meta?.alias || [],
    redirect: editAIBDblgH77Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSH5TaRTW0nMeta?.name ?? "forms-slug",
    path: indexSH5TaRTW0nMeta?.path ?? "/forms/:slug()",
    meta: indexSH5TaRTW0nMeta || {},
    alias: indexSH5TaRTW0nMeta?.alias || [],
    redirect: indexSH5TaRTW0nMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showK41TTb8GcEMeta?.name ?? undefined,
    path: showK41TTb8GcEMeta?.path ?? "/forms/:slug()/show",
    meta: showK41TTb8GcEMeta || {},
    alias: showK41TTb8GcEMeta?.alias || [],
    redirect: showK41TTb8GcEMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexmPYy6sFEluMeta?.name ?? "forms-slug-show",
    path: indexmPYy6sFEluMeta?.path ?? "",
    meta: indexmPYy6sFEluMeta || {},
    alias: indexmPYy6sFEluMeta?.alias || [],
    redirect: indexmPYy6sFEluMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexADtKFLecWXMeta?.name ?? "forms-slug-show-integrations",
    path: indexADtKFLecWXMeta?.path ?? "integrations",
    meta: indexADtKFLecWXMeta || {},
    alias: indexADtKFLecWXMeta?.alias || [],
    redirect: indexADtKFLecWXMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: sharewUd8P3124yMeta?.name ?? "forms-slug-show-share",
    path: sharewUd8P3124yMeta?.path ?? "share",
    meta: sharewUd8P3124yMeta || {},
    alias: sharewUd8P3124yMeta?.alias || [],
    redirect: sharewUd8P3124yMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: stats2crGp1qfoaMeta?.name ?? "forms-slug-show-stats",
    path: stats2crGp1qfoaMeta?.path ?? "stats",
    meta: stats2crGp1qfoaMeta || {},
    alias: stats2crGp1qfoaMeta?.alias || [],
    redirect: stats2crGp1qfoaMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionsuS8CTTJdRqMeta?.name ?? "forms-slug-show-submissions",
    path: submissionsuS8CTTJdRqMeta?.path ?? "submissions",
    meta: submissionsuS8CTTJdRqMeta || {},
    alias: submissionsuS8CTTJdRqMeta?.alias || [],
    redirect: submissionsuS8CTTJdRqMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: guestOxe6FiTSDhMeta?.name ?? "forms-create-guest",
    path: guestOxe6FiTSDhMeta?.path ?? "/forms/create/guest",
    meta: guestOxe6FiTSDhMeta || {},
    alias: guestOxe6FiTSDhMeta?.alias || [],
    redirect: guestOxe6FiTSDhMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: indexLHNOATHabxMeta?.name ?? "forms-create",
    path: indexLHNOATHabxMeta?.path ?? "/forms/create",
    meta: indexLHNOATHabxMeta || {},
    alias: indexLHNOATHabxMeta?.alias || [],
    redirect: indexLHNOATHabxMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homevGTO74ez5BMeta?.name ?? "home",
    path: homevGTO74ez5BMeta?.path ?? "/home",
    meta: homevGTO74ez5BMeta || {},
    alias: homevGTO74ez5BMeta?.alias || [],
    redirect: homevGTO74ez5BMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexpvpOBIeCIIMeta?.name ?? "index",
    path: indexpvpOBIeCIIMeta?.path ?? "/",
    meta: indexpvpOBIeCIIMeta || {},
    alias: indexpvpOBIeCIIMeta?.alias || [],
    redirect: indexpvpOBIeCIIMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginWJwg8vmwt5Meta?.name ?? "login",
    path: loginWJwg8vmwt5Meta?.path ?? "/login",
    meta: loginWJwg8vmwt5Meta || {},
    alias: loginWJwg8vmwt5Meta?.alias || [],
    redirect: loginWJwg8vmwt5Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: emailfT1ph2OPI3Meta?.name ?? "password-email",
    path: emailfT1ph2OPI3Meta?.path ?? "/password/email",
    meta: emailfT1ph2OPI3Meta || {},
    alias: emailfT1ph2OPI3Meta?.alias || [],
    redirect: emailfT1ph2OPI3Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ZobYEFLvcrMeta?.name ?? "password-reset-token",
    path: _91token_93ZobYEFLvcrMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93ZobYEFLvcrMeta || {},
    alias: _91token_93ZobYEFLvcrMeta?.alias || [],
    redirect: _91token_93ZobYEFLvcrMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricing1rlXi5t3XSMeta?.name ?? "pricing",
    path: pricing1rlXi5t3XSMeta?.path ?? "/pricing",
    meta: pricing1rlXi5t3XSMeta || {},
    alias: pricing1rlXi5t3XSMeta?.alias || [],
    redirect: pricing1rlXi5t3XSMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyNZ36VaUnk1Meta?.name ?? "privacy-policy",
    path: privacy_45policyNZ36VaUnk1Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyNZ36VaUnk1Meta || {},
    alias: privacy_45policyNZ36VaUnk1Meta?.alias || [],
    redirect: privacy_45policyNZ36VaUnk1Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerwXaD3ciyvdMeta?.name ?? "register",
    path: registerwXaD3ciyvdMeta?.path ?? "/register",
    meta: registerwXaD3ciyvdMeta || {},
    alias: registerwXaD3ciyvdMeta?.alias || [],
    redirect: registerwXaD3ciyvdMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsNmDj0jVjhvMeta?.name ?? undefined,
    path: settingsNmDj0jVjhvMeta?.path ?? "/settings",
    meta: settingsNmDj0jVjhvMeta || {},
    alias: settingsNmDj0jVjhvMeta?.alias || [],
    redirect: settingsNmDj0jVjhvMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensNUQcgwF2QAMeta?.name ?? "settings-access-tokens",
    path: access_45tokensNUQcgwF2QAMeta?.path ?? "access-tokens",
    meta: access_45tokensNUQcgwF2QAMeta || {},
    alias: access_45tokensNUQcgwF2QAMeta?.alias || [],
    redirect: access_45tokensNUQcgwF2QAMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountLMqiuVKUNMMeta?.name ?? "settings-account",
    path: accountLMqiuVKUNMMeta?.path ?? "account",
    meta: accountLMqiuVKUNMMeta || {},
    alias: accountLMqiuVKUNMMeta?.alias || [],
    redirect: accountLMqiuVKUNMMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: admin0pwYE3ykjNMeta?.name ?? "settings-admin",
    path: admin0pwYE3ykjNMeta?.path ?? "admin",
    meta: admin0pwYE3ykjNMeta || {},
    alias: admin0pwYE3ykjNMeta?.alias || [],
    redirect: admin0pwYE3ykjNMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingJrbHyxKDRmMeta?.name ?? "settings-billing",
    path: billingJrbHyxKDRmMeta?.path ?? "billing",
    meta: billingJrbHyxKDRmMeta || {},
    alias: billingJrbHyxKDRmMeta?.alias || [],
    redirect: billingJrbHyxKDRmMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsSW3KIwa3xhMeta?.name ?? "settings-connections",
    path: connectionsSW3KIwa3xhMeta?.path ?? "connections",
    meta: connectionsSW3KIwa3xhMeta || {},
    alias: connectionsSW3KIwa3xhMeta?.alias || [],
    redirect: connectionsSW3KIwa3xhMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: indexPFhNCEa17oMeta?.name ?? "settings",
    path: indexPFhNCEa17oMeta?.path ?? "",
    meta: indexPFhNCEa17oMeta || {},
    alias: indexPFhNCEa17oMeta?.alias || [],
    redirect: indexPFhNCEa17oMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordmaAPVwfv7dMeta?.name ?? "settings-password",
    path: passwordmaAPVwfv7dMeta?.path ?? "password",
    meta: passwordmaAPVwfv7dMeta || {},
    alias: passwordmaAPVwfv7dMeta?.alias || [],
    redirect: passwordmaAPVwfv7dMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileC4pJeHGgdWMeta?.name ?? "settings-profile",
    path: profileC4pJeHGgdWMeta?.path ?? "profile",
    meta: profileC4pJeHGgdWMeta || {},
    alias: profileC4pJeHGgdWMeta?.alias || [],
    redirect: profileC4pJeHGgdWMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacePB5CDfFlLzMeta?.name ?? "settings-workspace",
    path: workspacePB5CDfFlLzMeta?.path ?? "workspace",
    meta: workspacePB5CDfFlLzMeta || {},
    alias: workspacePB5CDfFlLzMeta?.alias || [],
    redirect: workspacePB5CDfFlLzMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorU3S0fxOq93Meta?.name ?? "subscriptions-error",
    path: errorU3S0fxOq93Meta?.path ?? "/subscriptions/error",
    meta: errorU3S0fxOq93Meta || {},
    alias: errorU3S0fxOq93Meta?.alias || [],
    redirect: errorU3S0fxOq93Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successYQnNmryqT0Meta?.name ?? "subscriptions-success",
    path: successYQnNmryqT0Meta?.path ?? "/subscriptions/success",
    meta: successYQnNmryqT0Meta || {},
    alias: successYQnNmryqT0Meta?.alias || [],
    redirect: successYQnNmryqT0Meta?.redirect,
    component: () => import("/usr/src/app/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93G6770dyCnVMeta?.name ?? "templates-slug",
    path: _91slug_93G6770dyCnVMeta?.path ?? "/templates/:slug()",
    meta: _91slug_93G6770dyCnVMeta || {},
    alias: _91slug_93G6770dyCnVMeta?.alias || [],
    redirect: _91slug_93G6770dyCnVMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexI6zxLZoInCMeta?.name ?? "templates",
    path: indexI6zxLZoInCMeta?.path ?? "/templates",
    meta: indexI6zxLZoInCMeta || {},
    alias: indexI6zxLZoInCMeta?.alias || [],
    redirect: indexI6zxLZoInCMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93toIlw7UjwUMeta?.name ?? "templates-industries-slug",
    path: _91slug_93toIlw7UjwUMeta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93toIlw7UjwUMeta || {},
    alias: _91slug_93toIlw7UjwUMeta?.alias || [],
    redirect: _91slug_93toIlw7UjwUMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templatesRN7Lr1vmIEMeta?.name ?? "templates-my-templates",
    path: my_45templatesRN7Lr1vmIEMeta?.path ?? "/templates/my-templates",
    meta: my_45templatesRN7Lr1vmIEMeta || {},
    alias: my_45templatesRN7Lr1vmIEMeta?.alias || [],
    redirect: my_45templatesRN7Lr1vmIEMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K2L4W4pSHeMeta?.name ?? "templates-types-slug",
    path: _91slug_93K2L4W4pSHeMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93K2L4W4pSHeMeta || {},
    alias: _91slug_93K2L4W4pSHeMeta?.alias || [],
    redirect: _91slug_93K2L4W4pSHeMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsFuCLDLYc9MMeta?.name ?? "terms-conditions",
    path: terms_45conditionsFuCLDLYc9MMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsFuCLDLYc9MMeta || {},
    alias: terms_45conditionsFuCLDLYc9MMeta?.alias || [],
    redirect: terms_45conditionsFuCLDLYc9MMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: update_45credentialsbTPNyk6uBKMeta?.name ?? "update-credentials",
    path: update_45credentialsbTPNyk6uBKMeta?.path ?? "/update-credentials",
    meta: update_45credentialsbTPNyk6uBKMeta || {},
    alias: update_45credentialsbTPNyk6uBKMeta?.alias || [],
    redirect: update_45credentialsbTPNyk6uBKMeta?.redirect,
    component: () => import("/usr/src/app/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]